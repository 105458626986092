<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="user_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="user_phone" label="用户手机号" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template slot="header">
          <y_choose_member @change="newOne">
            <el-button>添加员工</el-button>
          </y_choose_member>
        </template>
        <template v-slot="s">
          <el-button @click="del(s.row)" type="info">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
import y_choose_member from "@/components/user/YUserChoose";
export default {
  name: "manage",
  components: {y_choose_member},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {},
      choose_member: {},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    newOne(e){
      this.$u.api.card.merchantClerkEdit({
        uid:e.id,
        merchant_id:parseInt(this.$route.query.merchant_id)
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    del({id}){
      this.$u.api.card.merchantClerkDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.merchantClerkSearch({
        page: this.page,
        merchant_id:parseInt(this.$route.query.merchant_id)
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>